<script setup lang="ts">
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/vue'
import type { JobItem } from '~/models/Job'

interface Props {
  datas: any
}

const props = defineProps<Props>()
const isLoading = ref(true)
const jobList = ref()
const loadMore = ref(false)
const selectedTab = ref(props.datas?.tab_default)
const searchConfig = useSearchApiConfig()

// INITIAL FETCH
jobList.value = props.datas?.tab_options.map((item: any) => ({ ...item, job: [] }))
await Promise.all([useAsyncData('get_authors', () => getJobBase(props.datas?.tab_default), { server: true })]).then((values) => {
  jobList.value[0].job = values[0]?.data.value.hits
  isLoading.value = false
})

async function fetchJobList(keyword: string, index: number) {
  if (isEmpty(jobList.value) || isEmpty(jobList.value[index]?.job)) {
    isLoading.value = true
    try {
      const jobs = await getJobBase(keyword)
      jobList.value[index].job = jobs.hits
    }
    finally {
      isLoading.value = false
    }
  }
}

async function getJobBase(keyword: string): Promise<JobItem[]> {
  const response = await searchConfig('/jobs/search', {
    method: 'POST',
    body: {
      country: useGetSubdomain(),
      city: '',
      types: ['full_time', 'part_time', 'freelance', 'internship'],
      remote: '',
      term: keyword,
      from: 0,
      size: 6,
      sort: 'date',
      type: 'simple_title_search',
    },
  })

  return response
}
</script>

<template>
  <BaseAtomsSkeletonBounded :class="`${props.datas?.max_width ? props.datas?.max_width : 'px-5 !lg:p-0'}`" class="text-gray-900 grid" container="full">
    <h2 class="text-2xl lg:text-4xl font-bold text-center mb-6">
      {{ props.datas?.title }}
    </h2>

    <TabGroup>
      <TabList class="flex space-x-2 mx-auto" style="width: fit-content;">
        <Tab v-for="(item, idx) in jobList" :key="idx" v-slot="{ selected }" as="template" @click="fetchJobList(item.value, idx), loadMore = false, selectedTab = item.value">
          <button class="px-2 pb-2" :class="[selected ? 'font-bold border-b-2 border-[#5843E5] focus:ring-0 focus:outline-none text-[#212121]' : 'text-[#666666]']">
            {{ item.label }}
          </button>
        </Tab>
      </TabList>

      <TabPanels class="mt-6">
        <TabPanel v-for="(item, idx) in jobList" :key="idx" class="focus:ring-0 focus:ring-offset-0 grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2 md:gap-4 lg:gap-6">
          <BrowsejobsJobCard v-for="(data, idxData) in isLoading ? 6 : item.job" :key="idxData" :is-loading="isLoading" :item="data" :job-id="data.job_id" class="hidden md:block" />
          <BrowsejobsJobCard v-for="(data, idxData) in isLoading ? 3 : (!isEmpty(item.job) ? item.job.slice(0, loadMore ? 6 : 3) : 3)" :key="idxData" :is-loading="isLoading" :item="data" :job-id="data.job_id" class="block md:hidden" />
        </TabPanel>
      </TabPanels>
    </TabGroup>

    <div v-if="!isLoading" class="mt-6 lg:mt-10 <md:grid grid-cols-1 flex">
      <div v-if="!loadMore" class="flex items-center text-sm font-bold text-gray-600 mb-4 mx-auto cursor-pointer md:hidden" @click="loadMore = true">
        <div class="flex flex-shrink-0">
          <Icon name="material-symbols:refresh" class="w-5 h-5 mr-2" />
        </div>
        Load More
      </div>
      <BaseAtomsButton :to="`${props.datas?.button_url}&q=${selectedTab}`" outline size="sm" class="text-sm font-bold border-[#5843E5] <md:w-full md:px-20 mx-auto">
        {{ props.datas?.button_label.replace('job_keyword', jobList.find((item: any) => item.value === selectedTab)?.label) }}
      </BaseAtomsButton>
    </div>
  </BaseAtomsSkeletonBounded>
</template>
